<template>
  <div class="spent">
    <div class="title-section">
      <h1 class="title">
        ROI
        <span class="text-muted">(spent Monetization)</span>
      </h1>

      <div class="roi-filters">
        <export :grid-api="api || undefined" />
      </div>
    </div>

    <filters :variant-options="variantOptions" />

    <section>
      <grid
        :columns="columns"
        :data="roi"
        :loading="$apollo.loading"
        :auto-group-column="autoGroupColumnDef"
        @ready="api = $event"
      />
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import countries from '../data/countries';
import { GET_ROI } from '../gql-requests';
import GridMixin from '../mixins/GridMixin';
import Export from '../components/roi/Export';

export default {
  components: { Export },
  mixins: [GridMixin],
  data() {
    return {
      variantOptions: [
        { key: 'country', name: 'Country' },
        { key: 'lunigroup', name: 'Luni Group' },
      ],
      appNames: {},
      roiByApp: [],
      roiObject: {},
      countries,
      api: null,
    };
  },
  computed: {
    ...mapGetters('filters', ['getDateRange', 'getGranularity', 'getVariant']),
    autoGroupColumnDef() {
      return {
        headerName: `App > ${this.getVariant && this.getVariant[0].toUpperCase() + this.getVariant.slice(1)}`,
        minWidth: 300,
        field: 'country',
        pinned: 'left',
      };
    },
    isVariantCountry() {
      return this.getVariant === 'country';
    },
    isVariantLuniGroup() {
      return this.getVariant === 'lunigroup';
    },
    timePeriods() {
      const timePeriods = [];
      const timePeriod = moment(this.getDateRange[0]);

      if (this.getGranularity === 'weekly') {
        timePeriod.date(1).day(8);
        if (timePeriod.date() > 7) {
          timePeriod.day(-6);
        }
      }

      const end = moment(this.getDateRange[1]).endOf('month');
      const fieldFormat = this.getGranularity === 'weekly' ? 'YYYYWW' : 'YYYYMM';
      const labelFormat = this.getGranularity === 'weekly' ? 'DD MMM YYYY' : 'MMMM YYYY';
      while (timePeriod.isSameOrBefore(end)) {
        timePeriods.push({
          field: timePeriod.format(fieldFormat),
          label: timePeriod.format(labelFormat),
        });
        const period = this.getGranularity === 'weekly' ? 'week' : 'month';
        timePeriod.add(1, period);
      }
      return timePeriods;
    },
    columns() {
      const columns = [
        {
          field: 'appName',
          colId: 0,
          headerName: 'App',
          rowGroup: true,
          hide: true,
        },
      ];

      columns.push({
        field: 'country',
        colId: 1,
        headerName: 'Country',
        filter: true,
        sortable: true,
        pinned: 'left',
        filterParams: {
          buttons: ['reset', 'apply'],
          debounceMs: 200,
        },
        maxWidth: 150,
        menuTabs: ['generalMenuTab', 'filterMenuTab'],
        hide: true,
      });

      columns.push({
        headerName: 'Total Spent',
        field: 'total',
        aggFunc: 'sum',
        sort: 'desc',
        hide: true,
      });

      this.timePeriods.forEach((period) => {
        columns.push({
          field: period.field,
          colId: Number(period.field),
          headerName: period.label,
          sortable: true,
          aggFunc: this.avgBySpent,
          // comparator: this.customComparator,
          valueGetter: (params) => {
            const currentData = params.data ? params.data[params.colDef.field] : undefined;
            const previousData = params.data ? params.data[params.colDef.field - 1] : undefined;
            const amount = this.calculateROI(currentData?.ads, currentData?.proceeds, currentData?.spent);
            const previousAmount = this.calculateROI(previousData?.ads, previousData?.proceeds, previousData?.spent);
            // const growth = previousAmount ? (amount - previousAmount) / previousAmount : undefined;
            const growth = previousAmount ? this.calculateGrowth(previousAmount, amount) : null;
            return {
              amount,
              previousAmount,
              growth,
              currentData,
              previousData,
            };
          },
          filterParams: {
            buttons: ['reset', 'apply'],
            debounceMs: 200,
          },
          maxWidth: 300,
          menuTabs: ['generalMenuTab'],
          cellRendererFramework: 'AmountWithGrowth',
          cellRendererParams: {
            showCurrency: false,
            precision: 2,
          },
        });
      });

      return columns;
    },
    roi() {
      const roi = [];
      Object.keys(this.roiObject).forEach((appId) => {
        Object.keys(this.roiObject[appId]).forEach((dim) => {
          const row = { appName: this.appNames[appId], country: dim };

          let total = 0;
          this.timePeriods.forEach((period) => {
            if (this.roiObject[appId][dim][period.field] === undefined) {
              row[period.field] = undefined;
            }

            const present = this.roiObject[appId][dim][period.field];
            row[period.field] = {
              spent: present?.spent || 0,
              ads: present?.ads || 0,
              proceeds: present?.proceeds || 0,
              roi: present?.roi || null,
            };
            total += present && present.spent ? present.spent : 0;
          });
          row.total = total;

          roi.push(row);
        });
      });
      return roi;
    },
  },
  watch: {
    roiByApp() {
      this.formatROIAgGrid();
    },
  },
  methods: {
    calculateROI(ads, proceeds, spent) {
      return ((proceeds || 0) + (ads || 0) - (spent || 0)) / ((spent && spent > 0) ? spent : 1);
    },
    withLeadingZero(number) {
      return `0${number}`.slice(-2);
    },
    customComparator(a, b) {
      const valA = a && a.roi ? Number(a.roi) : 0;
      const valB = b && b.roi ? Number(b.roi) : 0;
      if (valA === valB) return 0;
      return valA > valB ? 1 : -1;
    },
    avgBySpent(params) {
      const totalAds = { current: 0, previous: 0 };
      const totalProceeds = { current: 0, previous: 0 };
      const totalSpent = { current: 0, previous: 0 };
      params.forEach((value) => {
        totalAds.current += value.currentData?.ads || 0;
        totalAds.previous += value.previousData?.ads || 0;
        totalProceeds.current += value.currentData?.proceeds || 0;
        totalProceeds.previous += value.previousData?.proceeds || 0;
        totalSpent.current += value.currentData?.spent || 0;
        totalSpent.previous += value.previousData?.spent || 0;
      });
      const amount = this.calculateROI(totalAds.current, totalProceeds.current, totalSpent.current);
      const previousAmount = this.calculateROI(totalAds.previous, totalProceeds.previous, totalSpent.previous);
      const growth = previousAmount ? this.calculateGrowth(previousAmount, amount) : null;
      return { amount, previousAmount, growth };
    },
    formatROIAgGrid() {
      this.roiObject = {};

      this.roiByApp.forEach((raw) => {
        const timePeriod = this.getGranularity === 'weekly'
          ? `${raw.year}${this.withLeadingZero(raw.weekOfYear)}`
          : `${raw.year}${this.withLeadingZero(raw.month)}`;
        if (this.roiObject[raw.appId] === undefined) {
          this.roiObject[raw.appId] = {};
        }

        if (this.roiObject[raw.appId][raw.country] === undefined) {
          this.roiObject[raw.appId][raw.country] = {};
        }
        this.roiObject[raw.appId][raw.country][timePeriod] = {
          ads: raw.ads,
          spent: raw.spent,
          proceeds: raw.proceeds,
          roi: raw.roi,
        };

        if (this.appNames[raw.appId] === undefined) {
          this.appNames[raw.appId] = raw.appName;
        }
      });
    },
  },
  apollo: {
    roiByApp: {
      query: GET_ROI,
      skip() {
        return !this.getDateRange.length;
      },
      variables() {
        const variables = {
          weekly: this.getGranularity === 'weekly',
          variant: this.getVariant,
          isVariantCountry: this.isVariantCountry || this.isVariantLuniGroup,
        };
        if (this.getDateRange.length) {
          const from = new Date(this.getDateRange[0]);
          const to = new Date(this.getDateRange[1]);
          variables.from = `${from.getFullYear()}-${from.getMonth() + 1}`;
          variables.to = `${to.getFullYear()}-${to.getMonth() + 1}`;
        }
        return variables;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.roi-filters {
  display: flex;
  align-items: center;

  & > * {
    margin-left: 10px;
  }
}
</style>
