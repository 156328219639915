<template>
  <div class="export">
    <b-button
      @click="exportAsCsv('amount')"
    >
      Export
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'Export',
  props: {
    gridApi: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    exportAsCsv(valueToExport) {
      if (this.gridApi) {
        this.gridApi.exportDataAsCsv({
          allColumns: true,
          fileName: 'roi.csv',
          processCellCallback(params) {
            if (!params.value) {
              return null;
            }

            if (typeof params.value === 'string') {
              return params.value;
            }

            return params.value[valueToExport];
          },
        });
      }
    },
  },
};
</script>

<style scoped>
  .export {
    display: inline-block;
  }
</style>
